<template>
  <div>
    <div class="custom-search mb-2">
      <b-row>
        <b-col md="4">
          <label for="lead-type"> Найти по Email: </label>
          <b-form-input
            placeholder="example@email.com"
            class="w-60"
            v-model="filter"
          />
        </b-col>
      </b-row>
    </div>
    <users-table
      :users="usersList"
      @setStatus="setStatus"
      @changeTariff="changeTariff"
      @deleteUser="deleteUser"
      @showEditModal="editModal"
    />
    <b-modal
      id="modal-user"
      cancel-variant="outline-secondary"
      ok-title="Сохранить"
      cancel-title="Закрыть"
      centered
      title="Редактирование пользователя"
      @ok="saveCardEdit"
    >
      <b-form v-if="currentUser">
        <b-row>
          <b-col md="8">
            <label for="name">Email:</label>
            <b-form-input id="name" v-model="currentUser.email" disabled />
          </b-col>
          <b-col md="4">
            <label for="confirmed">Подтвержден:</label>
            <div style="padding: 0.438rem 1rem;">
              {{ currentUser.isConfirmed ? "Да" : "Нет" }}
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="8">
            <label for="createdAt">Дата регистрации:</label>
            <div style="padding: 0.438rem 1rem;">
              {{
                new Date(
                  currentUser.createdAt.seconds * 1000
                ).toLocaleDateString() +
                  " " +
                  currentUser.createdAt.toDate().toLocaleTimeString("ru-RU")
              }}
            </div>
          </b-col>
          <b-col md="4">
            <label for="status">Статус:</label>
            <b-form-checkbox
              v-model="currentUser.status"
              name="check-button"
              switch
            >
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4">
            <label for="tariff">Тариф:</label>
            <b-form-select :options="options" v-model="currentUser.tariff">
            </b-form-select>
          </b-col>
          <b-col md="8">
            <label for="tariffTill">Дата окончания:</label>
            <flat-pickr
              class="form-control"
              :config="configDateTill"
              v-model="tariffTill"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <label for="projectAmounts">Проектов:</label>
            <div
              class="cursor-pointer text-primary"
              style="padding: 0.438rem 1rem;"
              @click="
                $router.push({
                  name: 'admin-projects',
                  query: { user: currentUser.userId },
                })
              "
            >
              {{ currentUser.projectAmount }}
            </div>
          </b-col>
          <b-col>
            <label for="leadFormsAmounts">Лид Форм:</label>
            <div
              class="cursor-pointer text-primary"
              style="padding: 0.438rem 1rem;"
              @click="
                $router.push({
                  name: 'admin-lead-forms',
                  query: { user: currentUser.userId },
                })
              "
            >
              {{ leadFormsAmount }}
            </div>
          </b-col>
          <b-col>
            <label for="answersAmounts">Заявок:</label>
            <div
              class="cursor-pointer text-primary"
              style="padding: 0.438rem 1rem;"
              @click="
                $router.push({
                  name: 'admin-answers',
                  query: { user: currentUser.userId },
                })
              "
            >
              {{ answersAmount }}
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormSelect,
} from "bootstrap-vue";
import UsersTable from "./UsersTable.vue";
import { mapActions, mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    UsersTable,
    BFormInput,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
    flatPickr,
    ToastificationContent,
  },
  data() {
    return {
      filter: "",
      users: this.getUsers,
      currentUser: null,
      options: [
        { value: "Business", text: "Business" },
        { value: "Enterprise", text: "Enterprise" },
        { value: "Start", text: "Start" },
        { value: "admin", text: "admin" },
        { value: "Demo", text: "Demo" },
      ],
      configDateTill: {
        locale: Russian,
        dateFormat: "d.m.Y",
      },
      changedDate: null,
    };
  },
  computed: {
    ...mapGetters("admin", ["getUsers", "getProjects", "getLeadForms"]),
    usersList() {
      let users = [];
      for (const [key, value] of Object.entries(this.getUsers.answer)) {
        const projectAmount = this.getProjects.data.filter(
          (project) => project.userId === key
        ).length;
        const currentUser = { ...value, projectAmount, userId: key };
        users.push(currentUser);
      }
      if (this.filter && this.filter.length) {
        users = users.filter((user) => user.email.includes(this.filter));
      }
      return users;
    },
    tariffTill: {
      get() {
        if (this.currentUser) {
          const currentDate = new Date(
            this.currentUser.tariffTill.seconds * 1000
          ).toLocaleDateString("ru-RU");
          const dateArr = currentDate.split(".");
          this.changedDate = new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
          return currentDate;
        }
        return null;
      },
      set(newDate) {
        const dateArr = newDate.split(".");
        this.changedDate = new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
        // this.updateTariffTill({ user: this.currentUser, changedDate }).then(
        //   () => {
        //     this.requestUsers();
        //     this.$toast({
        //       component: ToastificationContent,
        //       props: {
        //         title: "Даты успешно изменены",
        //         icon: "CheckIcon",
        //       },
        //     });
        //   }
        // );
      },
    },
    leadFormsAmount() {
      return this.getLeadForms.data.length;
    },
    answersAmount() {
      let amount = 0;
      this.getLeadForms.data.forEach((leadForm) => {
        amount += leadForm.answers;
      });
      return amount;
    },
  },
  methods: {
    ...mapActions("admin", [
      "requestUsers",
      "requestProjects",
      "setUserStatus",
      "updateTariff",
      "updateTariffTill",
      "removeUser",
      "requestUsersLeadForms",
    ]),

    setStatus(user) {
      const key = Object.keys(this.getUsers.answer).find(
        (key) => this.getUsers.answer[key].email === user.email
      );
      this.setUserStatus({
        id: key,
        status: user.status,
      });
    },
    changeTariff(user) {
      this.updateTariff({ id: user.userId, tariff: user.tariff });
    },
    deleteUser(user) {
      this.removeUser(user).then(() => {
        console.log("Done");
        this.requestUsers();
      });
    },
    editModal(user) {
      this.currentUser = { ...user };
      this.requestUsersLeadForms(user);
      this.$bvModal.show("modal-user");
    },
    saveCardEdit() {
      console.log(this.currentUser, this.changedDate);
      this.changeTariff(this.currentUser);
      this.setStatus(this.currentUser);
      this.updateTariffTill({
        user: this.currentUser,
        changedDate: this.changedDate,
      }).then(() => {
        this.requestUsers();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Данные успешно обновлены",
            icon: "CheckIcon",
          },
        });
      });
    },
  },
  created() {
    this.requestUsers();
    this.requestProjects();
  },
};
</script>

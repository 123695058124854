<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="users"
      :rtl="direction"
      :bordered="false"
      class="projects-table"
      styleClass="vgt-table"
    >
      <div slot="emptystate" class="text-center">
        Нет пользователей
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'status'" class="text-nowrap">
          <span class="text-nowrap">
            <b-form-checkbox
              v-model="props.row.status"
              name="check-button"
              @change="$emit('setStatus', props.row)"
              switch
            >
            </b-form-checkbox>
          </span>
        </span>
        <span
          v-else-if="props.column.field === 'isConfirmed'"
          class="text-nowrap"
        >
          <span class="text-nowrap">
            {{ props.row.isConfirmed ? "Да" : "Нет" }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'tariff'" class="text-nowrap">
          <span class="text-nowrap">
            <b-form-select
              :options="options"
              v-model="props.row.tariff"
              @change="$emit('changeTariff', props.row)"
            >
            </b-form-select>
          </span>
        </span>
        <span
          v-else-if="props.column.field === 'createdAt'"
          class="text-nowrap"
        >
          <span class="text-nowrap">
            {{
              new Date(
                props.row.createdAt.seconds * 1000
              ).toLocaleDateString() +
                " " +
                props.row.createdAt.toDate().toLocaleTimeString("ru-RU")
            }}
          </span>
        </span>
        <span
          v-else-if="props.column.field === 'projectAmount'"
          class="text-nowrap "
        >
          <div
            class="text-nowrap cursor-pointer text-primary"
            @click="
              $router.push({
                name: 'admin-projects',
                query: { user: props.row.userId },
              })
            "
          >
            {{ props.row.projectAmount }}
          </div>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex justify-content-around">
            <span style="align-self: center;" class="mx-4">
              <feather-icon
                icon="Edit2Icon"
                size="18"
                class="text-body align-middle mr-25 cursor-pointer"
                @click="showUserModal(props.row)"
              />
            </span>
            <span style="align-self: center;" class="mx-2 mx-md-0">
              <feather-icon
                icon="TrashIcon"
                size="18"
                class="text-body align-middle mr-25 cursor-pointer"
                @click="deleteUser(props.row.userId)"
              />
            </span>
          </span>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { BFormCheckbox, BFormSelect } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueGoodTable,
    BFormCheckbox,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  props: ["users"],
  data() {
    return {
      dir: false,
      rows: [],
      columns: [
        {
          label: "Статус",
          field: "status",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Подтвержден",
          field: "isConfirmed",
        },
        {
          label: "Тарифный план",
          field: "tariff",
        },
        {
          label: "Дата регистрации",
          field: "createdAt",
        },
        {
          label: "Проектов в сервисе",
          field: "projectAmount",
        },
        {
          label: "",
          field: "action",
        },
      ],
      options: [
        { value: "Business", text: "Business" },
        { value: "Enterprise", text: "Enterprise" },
        { value: "Start", text: "Start" },
        { value: "admin", text: "admin" },
        { value: "Demo", text: "Demo" },
      ],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    changeTariff(user) {
      console.log(user);
    },
    showUserModal(user) {
      this.$emit("showEditModal", user);
    },
    deleteUser(user) {
      this.$swal({
        title: "Вы действительно хотите удалить пользователя?",
        text:
          "После удаления, его невозможно будет восстановить. Удалится так же вся статистика и информация о пользователе",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Да, удалить!",
        cancelButtonText: "Отменить",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$emit("deleteUser", user);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.projects-table tr:hover {
  background-color: #f8f8f8;
}
</style>
